@import "core";
.eventbox.type-regular {
	border: .1rem solid $color-bg;
	border-radius: $radius-small;
	display: flex;
	flex-direction: column;

	.eventbox-imagewrap {
		width: calc(100% + .2rem);
		margin: -.1rem -.1rem 0;
		padding-bottom: calc(135% + .2rem);
		position: relative;
	}

	.imagewrap-labels {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		right: 0;
		top: 0;
		padding: 1.5rem;
		z-index: 2;
		
		.badge {
			+ .badge {
				margin-top: .5rem;
			}
		}
	}

	.eventbox-image {
		// height: 29.4rem;
		border-radius: $radius-small;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.eventbox-text {
		padding: 1.1rem 2rem 2.1rem;
		width: 100%;
		flex: 1 0 0;
		display: flex;
		flex-direction: column;

		@include media {
			padding: .8rem .8rem 1.4rem;
		}
	}

	.eventbox-title {
		@include font-bold;
		font-size: 2rem;
		display: block;

		@include media {
			font-size: 1.6rem;
		}

		&.placeholder {
			height: 4rem;
		}
	}

	.eventbox-infofields {
		@include font-bold;
		font-size: 1.4rem;
		margin: 1.5rem 0;
		flex: 1 0 0;

		@include media {
			margin: 2.3rem 0 1.4rem;
			font-size: 1.2rem;
		}
	}

		.infofields-item {
			&.placeholder {
				height: 1.5rem;
				width: 80%;
			}

			+ .infofields-item {
				margin-top: .9rem;
			}
		}

	.eventbox-controls {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include media {
			display: block;
		}
	}

	.eventbox-buybtn {
		@include media {
			width: 100%;
		}

		&.placeholder {
			width: 7.8rem;
			height: 4rem;

			@include media {
				width: 100%;
			}
		}
	}

	.eventbox-detailsbtn {
		@include mediaMin {
			margin-left: 1.5rem;
		}

		@include media {
			width: 100%;
			margin-top: 1.5rem;
		}

		&.placeholder {
			width: 6.7rem;
			height: 1.4rem;
		}
	}
}