@import "core";
@mixin badgeColor(
	$bgColor,
	$textColor: $color-white,
	$hollowColor: false,
	$borderColor: false,
) {
	@if $hollowColor == false {
		$hollowColor: $bgColor;
	}

	@if $borderColor == false {
		$borderColor: $bgColor
	}

	background-color: $bgColor;
	border-color: $borderColor;
	color: $textColor;

	&.outline {
		background-color: transparent;
		color: $hollowColor;
		border-color: $borderColor;
	}
}

.badge {
	@include font-bold;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	padding: .1rem .7rem;
	line-height: 2.1rem;
	min-height: 2.5rem;
	font-size: 1.2rem;
	background-color: $color-primary;
	color: $color-white;
	border-radius: $radius-small;
	border: .1rem solid;

	@include badgeColor($color-primary, $color-white);

	+ .badge {
		margin-left: .7rem;
	}

	.badge-icon {
		font-size: 1.2rem;
		margin-right: .4rem;
	}

	&.big {
		font-size: 1.6rem;
		padding: .3rem 2.1rem;
		line-height: 2.9rem;
		min-height: 3.5rem;

		.badge-icon {
			font-size: 1.7rem;
			margin-right: 1.7rem;
			margin-left: -.9rem;
		}
	}

	&.small {
		font-size: .8rem;
		padding: .1rem 0.4rem;
		line-height: 1.8rem;
		min-height: 2rem;

		.badge-icon {
			font-size: .8rem;
			margin-right: .4rem;
			margin-left: -.2rem;
		}
	}

	&.icon-only {
		.badge-icon {
			margin-right: 0;
		}
	}

	// Colors
	&.variant-text {
		@include badgeColor($color-text, $color-white);
	}

	&.variant-warning {
		@include badgeColor($color-warning, $color-white);
	}

	&.variant-red-dark {
		@include badgeColor($color-red-dark, $color-white);
	}

	&.variant-red-darker {
		@include badgeColor($color-red-darker, $color-white);
	}

	&.variant-bg {
		@include badgeColor($color-bg, $color-white, $color-text);
	}
}